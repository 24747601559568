/* eslint-disable react/jsx-props-no-spreading */
import Image, { ImageLoaderProps, ImageProps } from 'next/image'
import { FC } from 'react'

const lemonImageLoader = ({ src, width, quality = 60 }: ImageLoaderProps) =>
  `${process.env.NEXT_PUBLIC_IMAGE_SERVER}/get-image?url=${src}&w=${width}&q=${quality}`

const LemonImage: FC<ImageProps> = ({ src, ...props }) => {
  if (src instanceof String) {
    return <Image src={src ?? '/'} {...props} loader={lemonImageLoader} />
  }
  return <Image src={src ?? '/'} {...props} />
}

export default LemonImage
