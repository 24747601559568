import { signOut, User as FirebaseUser } from 'firebase/auth'
import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

import { suspend } from 'suspend-react'
import { useLocalStorage } from '@sergeimeza/foundation-react'
import { useAuth } from '@sergeimeza/firebase-react'

import { trpc } from '../services/trpc'

import { auth } from '../services/firebase'

import { Prisma } from '../interfaces'

// Auth State enum
enum AuthState {
  SIGN_IN = 'SIGN_IN',
  SIGN_OUT = 'SIGN_OUT',
}

// Auth Context Interface
interface IAuth {
  // auth state
  state: AuthState | null
  // firebase user
  firebaseUser: FirebaseUser | null | undefined
  // app user
  appUser:
    | (Prisma.User & {
        permissions: Prisma.UserPermission[]
      })
    | null

  logOut: () => Promise<void>
}

// initial state
const initialState: IAuth = {
  state: null,
  firebaseUser: undefined,
  appUser: null,
  logOut: async () => {},
}

const SESSION_DURATION = 1000 * 60 * 15 // 15 minutes

const AuthContext = createContext<IAuth>(initialState)

const AuthProvider: FC<{
  token: string | null | undefined
  setToken: Dispatch<string | null | undefined>
  children: ReactNode
}> = ({ children, token, setToken }) => {
  const [authState, setAuthState] = useState(initialState.state)
  const [appUser, setAppUser] = useState<IAuth['appUser']>(null)

  const [firebaseUser] = useAuth(auth)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [session, setSession] = useLocalStorage<number | null>('session', null)

  const logOut = useCallback(async () => {
    setAuthState(AuthState.SIGN_OUT)
    setAppUser(null)
    setToken(null)
    await signOut(auth).then(() => {
      window.location.reload()
    })
  }, [setToken])

  const fetchAppToken = trpc.useMutation('auth.login', {
    retry: false,
  })

  const fetchAppUser = trpc.useMutation('auth.user', {
    retry: false,
    onError: error => {
      if (error?.data) {
        const { code } = error.data

        if (code === 'FORBIDDEN') {
          logOut()
        }
        if (code === 'UNAUTHORIZED') {
          logOut()
        }
      }
    },
  })

  suspend(async () => {
    if (firebaseUser === undefined) {
      return null
    }

    if (firebaseUser === null) {
      setAuthState(AuthState.SIGN_OUT)
      return null
    }

    const { data } = await fetchAppToken.mutateAsync({ uid: firebaseUser.uid })
    setToken(data)
    setSession(Date.now() + SESSION_DURATION)
    return data
  }, ['fetchAppToken', firebaseUser?.uid])

  suspend(async () => {
    // TODO: doesn't work after logout
    if (!token) {
      return
    }

    const appUserData = await fetchAppUser.mutateAsync()
    setAppUser(appUserData)
    if (appUserData) {
      setAuthState(AuthState.SIGN_IN)
    }
  }, ['fetchAppUser', token])

  // update auth context value
  const value: IAuth = useMemo(
    () => ({
      state: authState,
      firebaseUser,
      appUser,
      logOut,
    }),
    [appUser, authState, firebaseUser, logOut],
  )

  // console.log(
  //   JSON.stringify(
  //     {
  //       state: authState,
  //       firebaseUser: firebaseUser && '✅',
  //       appUser: appUser && '✅',
  //       token: token && '✅',
  //     },
  //     null,
  //     2,
  //   ),
  // )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

// convenient hook for consuming auth context
const useAuthContext = () => useContext(AuthContext)

export { AuthProvider, AuthState, useAuthContext }
