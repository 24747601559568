import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from 'react'
import { useRouter } from 'next/router'

import { en } from '../locales/en'
import { ja } from '../locales/ja'

import type { EnglishTranslation } from '../locales/en'
import type { JapaneseTranslation } from '../locales/ja'

// Translation Context Interface
interface ITranslation {
  translation: JapaneseTranslation & EnglishTranslation
  language: string | undefined
  setLanguage: (language: string) => void
}

// initial state
const initialState: ITranslation = {
  translation: ja,
  language: 'ja',
  setLanguage: () => {},
}

const TranslationContext = createContext<ITranslation>(initialState)

const TranslationProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { locale } = useRouter()
  const [language, setLanguage] = useState(locale)

  // update auth context value
  const value: ITranslation = useMemo(
    () => ({
      translation: language === 'ja' ? ja : en,
      language,
      setLanguage,
    }),
    [language],
  )

  return (
    <TranslationContext.Provider value={value}>
      {children}
    </TranslationContext.Provider>
  )
}

// convenient hook for consuming translation context
const useTranslationContext = () => useContext(TranslationContext)

export { TranslationProvider, useTranslationContext }
