import { FirebaseApp, getApp, getApps, initializeApp } from 'firebase/app'
import { getAnalytics, isSupported } from 'firebase/analytics'
import { getAuth } from 'firebase/auth'

// todo
// NEXT_PUBLIC_FIREBASE_API_KEY etc
const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
}

// eslint-disable-next-line import/no-mutable-exports
let app: FirebaseApp | undefined

if (getApps().length) {
  // client-side
  app = getApp()
} else {
  // server-side
  app = initializeApp(firebaseConfig)
}

const auth = getAuth(app)
const analytics = isSupported().then(
  supported => supported && getAnalytics(app),
)

export { auth, analytics }

export default app
