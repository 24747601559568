import {
  FC,
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
  ReactNode,
} from 'react'

/**
 * React context for storing theme-related data and callbacks.
 * `colorMode` is `light` or `dark` and will be consumed by
 * various downstream components, including `EuiProvider`.
 */
export const GlobalProvider = createContext<{
  colorMode?: string
  setColorMode?: (colorMode: string) => void
}>({})

export const Theme: FC<{ children: ReactNode }> = ({ children }) => {
  const [colorMode, setColorMode] = useState('light')

  // on initial mount in the browser, use any theme from local storage
  useEffect(() => {
    setColorMode('light')
  }, [])

  const value = useMemo(() => ({ colorMode, setColorMode }), [colorMode])

  return (
    <GlobalProvider.Provider value={value}>{children}</GlobalProvider.Provider>
  )
}

export const useTheme = () => useContext(GlobalProvider)
