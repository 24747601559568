const ja = {
  // 'Account management': 'アカウント管理',
  // Activate: '利用再開',
  DASHBOARD__AVERAGE_FOLLOWERS: '平均フォロワー数',
  DASHBOARD__AVERAGE_TIKTOK_FOLLOWERS: 'Tiktok平均フォロワー数',
  DASHBOARD__AVERAGE_INSTAGRAM_FOLLOWERS: 'Instagram平均フォロワー数',
  // Campaign: 'キャンペーン',

  header: {
    ID: 'ID',
    COMPANY_NAME: '会社名',
    BRAND_NAME: 'ブランド名',
    PRODUCT_NUMBER: '管理品番',
    PRODUCT_NAME: '商品名',
  },

  label: {
    BRAND: 'ブランド',
    BRAND_NAME: 'ブランド名',
    BRAND_LOGO: 'ブランドロゴ',
    COMPANY: '会社',
    COMPANY_NAME: '会社名',
    CONTRACT_TYPE: '契約プラン',
    STORE: 'ストア',
    STORE_NAME: 'ストア名',
    DOMAIN: 'ドメイン',
    CART_URL: 'CART URL',
    THANKS_URL: 'THANKS URL',
  },

  button: {
    CREATE: '作成',
    EDIT: '編集',
    UPDATE: '更新',
    CREATE_STORE: 'Create Store',
    CREATE_WEBSITE: 'ウエブサイト作成',
    CREATE_POST_LIST: '投稿リスト作成',
    CREATE_UGC_SET: 'UGCセット作成',
  },

  'Product name': '商品名',

  /** General */
  GENERAL__ACTION: 'アクション',
  GENERAL__ADD: '追加',
  GENERAL__AGE: '年齢',
  GENERAL__BRAND: 'ブランド',
  /** Admin */
  ADMIN__BRAND_INFORMATION: 'ブランド情報',
  ADMIN__BRAND_NAME: 'ブランド名',
  /** Brand */
  BRAND__ACTIVE: '利用中',
  BRAND__APPROVED: '承認済み',
  BRAND__BRAND_LOGO: 'ブランドロゴ',
  BRAND__BRAND_NAME: 'ブランド名',
  BRAND__ADD: 'ブランド追加',
  /** Campaign */
  CAMPAIGN__ALL_APPLICANTS: '応募者全員',
  CAMPAIGN__ALL_DEAL_PARTICIPANTS: '当選者全員',
  CAMPAIGN__SUBMIT_REQUEST: '申請する',
  CAMPAIGN__REJECT_PARTICIPANT: '当選解除',
  CAMPAIGN__PARTICIPANT__BRAND_COMPATIBILITY: 'ブランド相性度',
  CAMPAIGN__BRAND_NAME: 'ブランド名',
  CAMPAIGN__CAMPAIGN_ADVANCE_SETTINGS: 'キャンペーンの詳細設定',
  CAMPAIGN__CAMPAIGN_BASIC_SETTINGS: 'キャンペーンの基本設定',
  CAMPAIGN__INFLUECNER_INFO: 'インフルエンサーの情報',
  CAMPAIGN__PRODUCT_AND_SERVICE: 'キャンペーンの商品・サービス',
  CAMPAIGN__TASKS: 'キャンペーンのタスク',
  CAMPAIGN__SCHEDULES: 'キャンペーンのスケジュール',
  CAMPAIGN__CAMPAIGN_DETAILS: 'キャンペーン内容',
  CAMPAIGN__CAMPAIGN_ID: 'キャンペーンID',
  CAMPAIGN__CAMPAIGN_LIST: 'キャンペーンリスト',
  CAMPAIGN__CAMPAIGN_NAME: 'キャンペーン名',
  CAMPAIGN__CAMPAIGN_OUTLINE: 'キャンペーン概要',
  CAMPAIGN__CAMPAIGN_PRODUCT_SERVICE: 'キャンペーンの商品/サービス',
  CAMPAIGN__CAMPAIGN_REPORT: 'キャンペーンレポート',
  CAMPAIGN__PRETASK: '投稿下書き',
  CAMPAIGN__PRETASK_REVIEW_STATUS: '審査状況',
  CAMPAIGN__PRETASK_DETAILS: '投稿下書き内容',
  CAMPAIGN__PRETASK_MODIFICATION_REQUEST: '修正依頼',
  CAMPAIGN__APPROVE_PRETASK: '承認',
  CAMPAIGN__DENY_PRETASK: '却下',
  CAMPAIGN__REVIEWED: '審査完了',
  CAMPAIGN__ADMIN_REVIEW: 'Admin審査',
  CAMPAIGN__LAW_REVIEW: '薬事審査',
  CAMPAIGN__LAW_REVIEWED: '薬事審査完了',
  CAMPAIGN__CLIENT_REVIEW: 'クライアント審査',
  CAMPAIGN__CLIENT_REVIEWED: 'クライアント審査完了',
  CAMPAIGN__TASK_DETAILS: '投稿内容',
  CAMPAIGN__TASK_CHECK: '投稿確認',
  CAMPAIGN__FIX_REWARD_AMOUNT: '報酬額確定',
  CAMPAIGN__STATUS_OF_REWARD_AMOUNT_FIXED: '報酬額確定状況',
  CAMPAIGN__COMPLETED_PAYMENT_CONFIRMATION: '確定済',
  CAMPAIGN__DESIRED_REWARD_AMOUNT: '希望報酬額',
  CAMPAIGN__NUMBER_OF_PARTICIPANTS: '応募者数',
  CAMPAIGN__ARCHIVE_CAMPAIGN: 'キャンペーンのアーカイブ',

  /** Client */
  CLIENT__ADD_NEW: 'クライアント追加',
  CLIENT__ADDRESS: '住所',
  CLIENT__ALL: 'クライアント一覧',
  CLIENT__ALL_MEMBERS: 'メンバー一覧',
  CLIENT__CHANGE_MEMBER_ROLE: 'このユーザーの権限を変更してもよろしいですか？',
  CLIENT__REMOVE_MEMBER: 'このメンバーを削除してもよろしいですか？',
  /** Fan Marketing */
  FAN_MARKETING__ADD_INFLUENCER: 'インフルエンサーを追加',
  FAN_MARKETING_ADD_INSTAGRAM:
    '登録したいインフルエンサーのInstagramアカウントを追加してください',
  FAN_MARKETING_ADD_TIKTOK:
    '登録したいインフルエンサーのTikTokアカウントを追加してください',
  /** Influencer */
  INFLUENCER__AVG_COMMENTS: '平均コメント数',
  INFLUENCER__AVG_LIKES: '平均いいね数',
  INFLUENCER__AVG_VIEWS: '平均動画再生数',

  /** Influencer Search */
  INFLUENCER__SEARCH__FOLLOWERS: 'フォロワー数',
  INFLUENCER__SEARCH__AGE_RATIO: '年代比',
  INFLUENCER__SEARCH__GENDER_RATIO: '男女比',
  INFLUENCER__SEARCH__LAST_ACTIVITY: '最終応募日',
  INFLUENCER__SEARCH__10S: '10代',
  INFLUENCER__SEARCH__20S: '20代',
  INFLUENCER__SEARCH__30S: '30代',
  INFLUENCER__SEARCH__40S: '40代以上',
  INFLUENCER__SEARCH__AGE_GROUP: '年齢層',
  INFLUENCER__SEARCH__CREDIBILITY_SCORE: '信用度スコア',
  INFLUENCER__SEARCH__APPLIED_CAMPAIGN_COUNT: '応募したキャンペーン数',
  INFLUENCER__SEARCH__WON_CAMPAIGN_COUNT: '当選したキャンペーン数',
  INFLUENCER__SEARCH__WOMAN_FILTER: 'フォロワーの女性比率でフィルター',
  INFLUENCER__SEARCH__JP_FOLLOWER_FILTER: '日本人フォロワー率でフィルター',
  INFLUENCER__SEARCH__WINNING_RATE_FILTER: '当選率でフィルター',
  INFLUENCER__SEARCH__WINNING_RATE: '当選率',
  INFLUENCER__SEARCH__ACTIVITY_3_MONTHS: '最近3ヶ月以内の活動',
  INFLUENCER__SEARCH__CAMPAIGN_3_MONTHS: '最近3ヶ月以内に参加したキャンペーン',
  INFLUENCER__SEARCH__JP_FOLLOWER: '日本人フォロワー率',
  INFLUENCER__SEARCH__PLATFORM: 'SNSプラットフォーム',
  INFLUENCER__SEARCH__SEARCH___: '検索...',
  INFLUENCER__SEARCH__SEARCH: '検索',
  INFLUENCER__SEARCH__SEND_DM: 'チャット一斉送信',

  INFLUENCER__PERSONAL__INFLUENCER_ID: 'インフルエンサーID',

  INSTAGRAM__SEARCH__FORMAT: '投稿形式',
  INSTAGRAM__SEARCH__IMAGE: '写真',
  INSTAGRAM__SEARCH__ALBUM: 'アルバム',
  INSTAGRAM__SEARCH__VIDEO: '動画',
  INSTAGRAM__SEARCH__LIKE: 'いいね',
  INSTAGRAM__SEARCH__COMMENT: 'コメント数',
  INSTAGRAM__SEARCH__TEXT_IN_IMAGE: '画像内のテキスト',
  INSTAGRAM__SEARCH__CONTAIN: '有り',
  INSTAGRAM__SEARCH__NOT_CONTAIN: '無し',
  INSTAGRAM__SEARCH__DATE: '投稿日',

  /** Product */
  PRODUCT__ADD: '商品を追加',
  PAYMENT__MANAGEMENT: '支払い管理',
  /** Warehouse */
  WAREHOUSE__ARRIVAL_COMPLETED: '納品完了',
  WAREHOUSE__BEFORE_ARRIVAL: '入荷前',
  WAREHOUSE__CAMPAIGN_ID: 'キャンペーンID',
  WAREHOUSE__PRODUCT_NAME: '商品名',
  WAREHOUSE__PRODUCT_NUMBER: '製品番号',

  APPROVE: '承認',
  APPROVED: '承認済み',
  ARCHIVE: 'アーカイブ',
  ARCHIVED: 'アーカイブ',
  'Before selection': '選定前',
  BIRTHDATE: '誕生日',
  BLACKLIST_INSERT: 'ブラックリストに入れる',
  BLACKLIST_REMOVE: 'ブラックリストから外す',
  CAMPAIGN__ARCHIVE_MESSAGE: 'このキャンペーンをアーカイブします。',
  CAMPAIGN__ID: 'キャンペーンID',
  CONFIRM_MESSAGE: 'よろしいですか？',
  CAMPAIGN__SCHEDULE: 'キャンペーンのスケジュール',
  CAMPAIGN__TASK: 'キャンペーンのタスク',
  CAMPAIGN__TYPE: 'キャンペーン種別',
  CANCEL: 'キャンセル',
  CHANGE_PASSWORD: 'パスワード変更',
  CHANGE_ROLE: '役割を変更',
  CHAT: 'チャット',
  CHECKBOX: '複数選択',
  SELECT_LANGUAGE: '言語選択',
  SELECT_FILE: 'ファイル選択',
  CITY: '市区町村',
  CLEAR: 'クリア',
  CLIENT__MANAGEMENT: 'クライアント管理',
  CLIENT__MEMBER: 'メンバー',
  CLIENT__OWNER: '管理者',
  CLOSE: '閉じる',
  PRODUCT_PRICE: '商品価格',
  COMPANY_INFORMATION: '会社情報',
  COMPANY_NAME: '会社名',
  REGISTRATION_COMPLETED: '登録完了',
  PASSWORD_CONFIRMATION: '確認用パスワード',
  CONNECT_TO_BRAND: 'ブランドと紐づける',
  CONTACT_HERE: 'お問い合わせ',
  CONTRACT_PLAN: '契約プラン',
  CREATE: '作成',
  CREATE_NEW: '新規作成',
  CREATE_BRAND: 'ブランド作成',
  UPDATE_BRAND: 'ブランド更新',
  CREATE_STORE: 'ストア作成',
  UPDATE_STORE: 'ストア更新',
  CREATE_CAMPAIGN: 'キャンペーン作成',
  CREATE_ORIENTATION_SHEET: 'オリエンテーションシートを作成する',
  CREATE_COMPANY: '会社作成',
  CREATE_PRODUCT: '商品作成',
  CREDIBILITY: '信用度',
  COPY: 'コピー',
  'Current password': '現在のパスワード',
  'Current role': '現在の権限',
  'Campaign report count': 'キャンペーン レポート数',
  'Chat list': 'チャットリスト',
  Dashboard: 'ダッシュボード',
  Day: '日',
  Deactivate: '利用停止にする',
  Deactivated: '利用停止中',
  'Deadline for draft submission': '下書き提出期限日',
  'Deadline for participation': '参加期限日',
  'Deadline for selection': '選定期限日',
  'Deadline for SNS posting': '投稿期限日',
  'Deal participants': '当選者',
  Defectives: '欠損品',
  DELETE: '削除',
  'Delivery completed': '発送完了',
  'Delivery list': '発送一覧',
  'Delivery management': '発送管理',
  'Delivery target': '発送対象',
  Detail: '詳細',
  'Detail filter': '詳細フィルター',
  'Direct shipping to influencers': 'インフルエンサーへの直接配送',
  'Disclosure range': '公開範囲',
  CONFIRMATION__CREATE_BRAND_MESSAGE: 'ブランドを作成しますか？',
  'Do you create a product?': '商品を作成しますか？',
  'Do you save a company?': '会社を保存しますか？',
  'Do you send a registration email?': '登録メールを送信しますか？',
  'Do you send products?': '商品を出荷しますか？',
  CONFIRMATION__UPDATE_BRAND_MESSAGE: 'ブランドを更新しますか？',
  'Do you update a product?': '商品を更新しますか？',
  'Do you update company info?': '会社情報を更新しますか？',
  'Do you update delivery info?': '発送情報を更新しますか？',
  'Do you update inspection info?': '検品情報を更新しますか？',
  'Do you update stock info?': '在庫情報を更新しますか？',
  'Due date for review': '提出締切日',
  Draft: '下書き',
  DRAFT: '下書き',
  DROPDOWN_MESSAGE: '選択してください',
  Duplicate: '複製する',
  Edit: '編集',
  'Edit profile': 'プロフィール編集',
  'Email address': 'メールアドレス',
  'Engagement rate': 'エンゲージメント率',
  Essentials: '必須事項',
  'Expected shipping date': '発送予定日',
  'Export excel': 'エクセル出力',
  'Export CSV': 'CSV出力',
  'Export all participants': '応募者エクスポート',
  'Export deal participants': '当選者エクスポート',
  'Export report': 'エクスポートレポート',
  'End date': '終了日',
  'ending-order': '終了日の昇順',
  'Enter child information': 'お子様情報の入力',
  'Enter date of birth': '生年月日の入力',
  'Fan score': 'ファンスコア',
  Filter: '絞込み',
  FINISHED: '公開終了',
  'First name': '名',
  FOLLOWERS: 'フォロワー数',
  'Follower growth rate': 'フォロワー数成長率',
  'For companies': '企業の方へ',
  'For influencers': 'インフルエンサーの方へ',
  'Forgot password?': 'パスワードを忘れた方はこちら',
  Gender: '性別',
  Gifting: 'ギフティング',
  Hashtags: 'ハッシュタグ',
  'LS attribution': '関係性の明示',
  'LS mention': 'LSメンション',
  'LS hashtags': 'LSハッシュタグ',
  'PR tag': 'PRタグ',
  'Legal check': '薬機チェック',
  'I agree with the Privacy Policy': 'プライバシーポリシーに同意します',
  Impression: 'インプレッション',
  'Including BK when export': 'エクスポート時にBKを含める',
  Influencer: 'インフルエンサー',
  INFLUENCER__ID: 'インフルエンサーID',
  'In-house product_numbers': '社内品番',
  'Influencer info': 'インフルエンサーの情報',
  'Influencer list': 'インフルエンサーリスト',
  'Influencer personal check': '本人確認',
  'Influencer antisocial check': '反社チェック',
  'Influencers with a high engagement rate':
    'エンゲージメント率の高いインフルエンサー',
  'Influencers with a large number of posts': '投稿数の多いインフルエンサー',
  'Influencers with a lot of likes': 'いいね数の多いインフルエンサー',
  'Influencers with many applications': '応募の多いインフルエンサー',
  'Influencers with many followers': 'フォロワー数の多いインフルエンサー',
  INPUT_NUMERICAL_VALUE: '数字のみ入力してください',
  'Inspection completed': '検品完了',
  'Inspection management': '検品管理',
  'Instagram registered person': 'Instagram 登録者',
  Invite: '招待する',
  'Invite new member': 'メンバー招待',
  'Is this pre campaign?': 'プレキャンペーンに設定しますか',
  'Is this semi pre campaign?': 'セミプレキャンに設定しますか',
  'Last name': '姓',
  'Lemon Square active user': 'Lemon Square アクティブユーザー',
  'Lemon Square support': 'Lemon Squareサポート',
  'Phone number verification status': '電話番号の確認ステータス',
  'Pre-task list': '下書きリストを投稿する',
  'Pre-task ID': '投稿下書きID',
  Verified: '検証済み',
  'Not verified': '検証されていない',
  'Identification process status': '識別プロセスのステータス',
  'Identification result status': '識別結果',
  'AML risk check process status': 'リスクプロセスのステータス',
  'AML risk check result status': 'リスク結果',
  'Status does not exist': 'ステータスが存在しません',
  'Value does not exist': '存在しません',
  Links: 'リンク',
  'Link pattern': 'リンク例',
  'Login as this user': 'このユーザーとしてログイン',
  'Login here': 'ログインはこちら',
  Logout: 'ログアウト',
  Mail: 'メール',
  'Mark as read': '既読にする',
  'Mark as unread': '未読とする',
  'Maximum 99 items': '最大99個まで設定できます',
  'Member management': 'メンバー管理',
  Memo: 'メモ',
  Mentions: 'メンション',
  Menu: 'メニュー',
  Month: '月',
  'Multiple selections allowed': '複数選択可',
  'My profile': 'プロフィール',
  Name: '氏名',
  'New password': '新しいパスワード',
  No: 'いいえ',
  'No users': 'ユーザーがいません',
  'Number of applicants': '応募数',
  'Number of participant joined': '応募人数推移',
  'Number of champaign creatives': '投稿件数推移',
  'Number sum for campaign participants': '応募合計',
  'Number sum for campaign creatives': '投稿件数合計',
  'Number of arrivals': '入荷数',
  'Number of bookmarks': 'ブックマーク数',
  'Number of campaign applications': 'キャンペーン応募数',
  'Number of comments': 'コメント数',
  'Number of defectives': '欠損数',
  'Number of deliveries': '納品数',
  'Number of distribution per person': '配布数 / 人',
  'Number of followers': 'フォロワー数',
  'Number of winners followers': '当選フォロワー数',
  'Number of goods': 'いいね数',
  'Number of inspection': '検品数',
  'Number of likes': 'ライク数',
  'Number of passing inspection': '検品通過数',
  'Number of posts': '投稿数',
  'Number of provides': '提供数',
  'Number of registrants': '登録者数',
  'Number of sending back': '返送数',
  'Number of share': 'シェア数',
  'Number of shipping': '出荷数',
  'Number of targets': '対象者数',
  'Number of uninspected': '未検品数',
  'Number of videos': '動画数',
  'Number of winners': '当選者数',
  'Number of estimated followers': '推定フォロワー数',
  'Number of estimated following': '推定フォロー数',
  'Number of estimated likes': '推定いいね数',
  'Number of accounts': 'アカウント数',
  'Current instagram': '現在のInstagram',
  'Current tiktok': '現在のTiktok',
  Optional: '任意',
  'Order total': '発注合計金額',
  Overview: '概要',
  'Owned brand': '所有ブランド',
  'Payment List': '支払いリスト',
  'Package name': '商品名称',
  'Participant status successfully changed': 'ステータスを変更しました',
  'Passing inspection': '検品通過',
  Password: 'パスワード',
  'Percentage of users who linked instagram': 'Instagram連携割合',
  'Phone number': '電話番号',
  'Please select a chat to get started': 'チャットルームを選択してください',
  'Please enter 7 characters': '7文字で入力してください',
  'Please enter the email address you would like to invite':
    '招待するメールアドレスを入力してください',
  'Please enter the product number etc. managed in-house':
    '社内で管理している品番などを入力してください',
  'Please enter up to 100 characters': '100文字以内で入力してください',
  'Please enter your information': '入力してください',
  'Please fill modification request content': '修正依頼内容をご記入ください。',
  'Please fill name and email address of the person you would like to invite':
    'メンバー招待する方の氏名とメールアドレスを入力してください',
  'Please select brands': 'ブランドを選択してください',
  'Please wait for previous review': '前の審査をお待ちください。',
  'Popular campaign': '人気のキャンペーン',
  'Post questionnaire': '事後アンケート',
  'Post URL': '投稿URL',
  'Postal code': '郵便番号',
  'PR post': 'PR投稿',
  Platform: 'プラットフォーム',
  Precampaign: 'プレキャンペーン',
  'Semi pre campaign': 'セミプレキャン',
  Prefecture: '都道府県',
  PREFECTURE_HOKKAIDO: '北海道',
  PREFECTURE_AOMORI: '青森県',
  PREFECTURE_IWATE: '岩手県',
  PREFECTURE_MIYAGI: '宮城県',
  PREFECTURE_AKITA: '秋田県',
  PREFECTURE_YAMAGATA: '山形県',
  PREFECTURE_FUKUSHIMA: '福島県',
  PREFECTURE_IBARAKI: '茨城県',
  PREFECTURE_TOCHIGI: '栃木県',
  PREFECTURE_GUNMA: '群馬県',
  PREFECTURE_SAITAMA: '埼玉県',
  PREFECTURE_CHIBA: '千葉県',
  PREFECTURE_TOKYO: '東京都',
  PREFECTURE_KANAGAWA: '神奈川県',
  PREFECTURE_NIIGATA: '新潟県',
  PREFECTURE_TOYAMA: '富山県',
  PREFECTURE_ISHIKAWA: '石川県',
  PREFECTURE_FUKUI: '福井県',
  PREFECTURE_YAMANASHI: '山梨県',
  PREFECTURE_NAGANO: '長野県',
  PREFECTURE_GIFU: '岐阜県',
  PREFECTURE_SHIZUOKA: '静岡県',
  PREFECTURE_AICHI: '愛知県',
  PREFECTURE_MIE: '三重県',
  PREFECTURE_SHIGA: '滋賀県',
  PREFECTURE_KYOTO: '京都府',
  PREFECTURE_OSAKA: '大阪府',
  PREFECTURE_HYOGO: '兵庫県',
  PREFECTURE_NARA: '奈良県',
  PREFECTURE_WAKAYAMA: '和歌山県',
  PREFECTURE_TOTTORI: '鳥取県',
  PREFECTURE_SHIMANE: '島根県',
  PREFECTURE_OKAYAMA: '岡山県',
  PREFECTURE_HIROSHIMA: '広島県',
  PREFECTURE_YAMAGUCHI: '山口県',
  PREFECTURE_TOKUSHIMA: '徳島県',
  PREFECTURE_KAGAWA: '香川県',
  PREFECTURE_EHIME: '愛媛県',
  PREFECTURE_KOCHI: '高知県',
  PREFECTURE_FUKUOKA: '福岡県',
  PREFECTURE_SAGA: '佐賀県',
  PREFECTURE_NAGASAKI: '長崎県',
  PREFECTURE_KUMAMOTO: '熊本県',
  PREFECTURE_OITA: '大分県',
  PREFECTURE_MIYAZAKI: '宮崎県',
  PREFECTURE_KAGOSHIMA: '鹿児島県',
  PREFECTURE_OKINAWA: '沖縄県',
  'Preferred age': '希望する年代',
  'Preferred gender': '希望する性別',
  'Preferred region': '希望する地域',
  'Pretask review status successfully updated': '審査ステータスを更新しました',
  Product: '商品',
  'Product detail': '商品詳細',
  'Product id': '商品ID',
  'Product list': '商品一覧',
  'Product management': '商品管理',
  PRODUCT__PRODUCT_NUMBER: '管理品番',
  'Product shipment': '商品出荷',
  'Product/Service image': '商品・サービス画像',
  'Post date on SNS': '提出日',
  Prohibitions: '禁止事項',
  'Publish term': '公開期間',
  radio: '単一選択',
  Reach: 'リーチ',
  READY: '公開中',
  'Register term': '応募期間',
  'Register user': 'ユーザー登録',
  'Registered person': 'Lemon Square 登録者',
  'Registration date': '登録日',
  REJECT: '審査却下',
  Report: 'レポート',
  'Reported participants': '投稿報告者',
  Reset: 'リセット',
  Required: '必須',
  'Request to write product-returning memo': 'メモを記入してください',
  'Reward amount': '報酬額',
  'Reward amounts have been finalized': '報酬額を確定しました',
  'Reward campaign or not': '報酬の有無',
  'Reward type': '報酬の種類',
  Role: '権限',
  'Role after change': '変更後の権限',
  RUNNING: '募集完了',
  'Running campaign': '実施中のキャンペーン',
  'Saas client': 'Saasクライアント',
  'Saas plan': 'Saasプラン',
  SAVE: '保存',
  'Save post URL': '投稿URLを保存',
  Search: '検索',
  SEARCH_CONDITION: '検索条件',
  'Search by IG account': 'IGアカウント検索',
  'Search account': 'アカウント検索',
  'Search campaign': 'キャンペーン検索',
  'Search content': '検索内容',
  'Search results': '検索結果',
  'Search with Campaign name, ID, brand name, etc.': `「ID」や、「キャンペーン名」、「ブランド名」等で検索して下さい`,
  'See all': '全て見る',
  Select: '選択',
  'Select all': '全て選択',
  'Selected applicants': '選択中の応募者',
  'Selected applicants followers': '選択中のフォロワー数',
  'Select brand': 'ブランドを選択',
  'Select company': '会社を選択',
  Send: '送信',
  'Send DM': 'DMを送信',
  'Send DM to all participants': '応募者全員にDM送信',
  'Send DM to deal participants': '当選者全員にDM送信',
  'Send an email to winners': '当選者にメールを送信',
  'Send back': '返送する',
  'Send invitation email': '招待メール送信',
  'Send registration email': '登録メール送信',
  'Setting spotlight': 'スポットライト設定',
  'Shipping instruction': '出荷指示書',
  'Show tasks and deadlines after winning':
    '当選後にタスク（投稿レギュレーション）と期限を表示します',
  'Sign in': 'ログイン',
  'Sign in with credentials': 'メールアドレスでログインする',
  'Sign up': '登録',
  'Sign up with credentials': 'メールアドレスで登録する',
  'Similar kol': '類似インフルエンサー',
  'Since last month': '先月比',
  'Since last week': '先週比',
  'SKU name': 'SKU名称',
  'SKU list': 'SKU一覧',
  'Some data are being edited. Please press the Update button.':
    '一部のデータを編集しています。更新ボタンを押してください。',
  'Show unread only': '未読のみ表示する',
  'Sort by latest message': '最新順に並び替える',
  'Sort by oldest message': '古い順に並べ替え',
  'Sort by A to Z': 'A > Z で並べ替え',
  'Sort by Z to A': 'Z > A で並べ替え',
  'ascending-order': '昇順',
  'descending-order': '降順',
  'Start date': '開始日',
  'Start of draft submission': '下書き提出開始日',
  'Start of participation': '参加開始日',
  'Start of SNS posting': '投稿開始日',
  'starting-order': '開始日の昇順',
  Status: 'ステータス',
  'Status of campaign': 'キャンペーンの実施状況',
  'Street, building name, etc': '番地, 建物名',
  'Stock list': '在庫一覧',
  Submit: '確認画面',
  SUBMISSION: '審査中',
  'Successfully saved': '保存しました',
  'Successfully updated': '更新しました',
  Summary: 'サマリー',
  'Support plan': 'サポートプラン',
  Tags: 'タグ',
  'Target group': '対象者',
  Tasks: 'タスク',
  text: '自由入力',
  'There is an additional fee as our handling.':
    '別途、弊社手数料がかかります。',
  'There is no data': 'データがありません',
  'There is no data to export': '出力するデータがありません',
  'This brand also registers the following products':
    'このブランドは以下の商品も登録しています',
  'This is the product category that KOL selects when applying for a campaign.':
    'KOLがキャンペーン応募時に選択する商品区分です',
  'This pretask has modification request':
    'こちらの投稿下書きは修正依頼があります。',
  'This pretask is approved': 'こちらの投稿下書きは承認されています。',
  'This pretask is unreviewed': 'こちらの投稿下書きはまだ審査されていません。',
  'TikTok registered person': 'TikTok 登録者',
  Title: 'タイトル',
  Total: '合計',
  'Total campaigns': 'キャンペーン総数',
  'Total clients': 'クライアント総数',
  'Total followers': '合計フォロワー数',
  'Total influencers': 'インフルエンサー総数',
  'Total instagram users': 'Instagramユーザー総数',
  'Total instagram campaigns': 'Instagramキャンペーン数',
  'Total tiktok campaigns': 'TikTokキャンペーン数',
  'Total tiktok users': 'TikTokユーザー総数',
  'Total hits': '検索結果',
  Town: '町名',
  // Unapproved: '未承認',
  'Unit price': '単価',
  Unknown: '不明',
  'Unreported participants': '投稿未報告者',
  Unreview: '未審査',
  Unsubmitted: '未提出',
  Unset: '未設定',
  UNSUBSCRIBED_USER: 'このユーザーは退会済みです',
  UPDATE: '更新',
  'Update brand': 'ブランド更新',
  'Update product': '商品更新',
  'Update delivery info': '発送情報の更新',
  'Update inspection info': '検品情報の更新',
  'Update stock info': '在庫情報の更新',
  'Warehouse inspection history': '倉庫での検品履歴',
  'Shipping product logs': '製品ログの発送',
  'Shipping Registration History': '出荷登録履歴',
  'Upload excel': 'Excelアップロード',
  User: 'ユーザー',
  USERNAME: 'ユーザー名',
  'User id': 'ユーザーID',
  'User information': 'ユーザー情報',
  'User name': 'ユーザー名',
  View: '表示',
  'Visit campaign': '来店型キャンペーン',
  'We sent an email so please check your inbox':
    'メールを送信しました。受信ボックスをご確認ください。',
  Week: '週',
  Website: 'ウェブサイト',
  'Welcome!': 'ようこそ！',
  'Welcome to lemon square': 'Lemon Square へようこそ',
  'Whether the product to be sent': '送付する商品の有無',
  'Without hyphen': 'ハイフン抜き',
  'Winning campaign count': '当選キャンペーン数',
  'Years old': '歳',
  Yes: 'はい',
  'You can add or edit products to be shipped in the campaign.':
    'キャンペーンで発送する商品の追加や編集を行うことができます',
  'You can manage the shipping status of products.':
    '商品の発送状態を管理することができます。',
  'Under review': '審査中',
  'Recruiting KOL': 'KOL募集中',
  'Recruitment completed / post collection': '募集完了・投稿回収',
  'End of publication': '公開終了',
  'Influencers with children': '子持ちインフルエンサー',
  'Personal information': '個人情報',
  'SNS authentication': 'SNS認証',
  'Last login date': '最終ログイン日時',
  'Last application date': '最終応募日時',
  'In preparation': '準備中',
  'The page you accessed is currently being prepared.':
    'アクセスいただいたページは現在準備中です。',
  'Please wait for a while until it is available.':
    'ご利用いただけるまでしばらくお待ちください。',
  "You've tried to send the verification email too many times":
    "You've tried to send the verification email too many times. Please try again later.",
  'Change email': 'Change email',
  'Confirm email': 'Confirm email',
  'Send Confirmation email': 'Send Confirmation email',
  "Email addresses don't match": "Email addresses don't match",
  'confirmation link sent':
    'A confirmation link was sent to your email. Please click on the link to verify your email address.',
  'confirmation link sent instruction':
    'An email was sent to your email. Please click on the link to verify your email address.',
  Email: 'Email',
  'Wrong password': 'Wrong password',
  'Account not found': 'Account not found',
  'Confirm password': 'Confirm password',
  'Wrong credentials': 'Wrong credentials',
  'Already have an account': 'Do you have an account? Sign in',
  'Select influencers': 'インフルエンサーを選択',
  'User blacklist status successfully changed':
    'ユーザーのブラックリストのステータスが正常に変更されました',
  Back: '戻る',
  PRIVATE: 'プライベートキャンペーン',
  PUBLIC: '公開キャンペーン',
  GIFTING: 'ギフティング',
  PAID_PROMOTION: 'PR投稿',
  MALE: '男性',
  FEMALE: '女性',
  Unspecified: '指定なし',
  "10's": '10代',
  "20's": '20代',
  "30's": '30代',
  "40's": '40代',
  Necessary: '必要',
  Unnecessary: '不要',
  'Has product': 'あり',
  'No product': 'なし',
  Do: 'する',
  "Don't": 'しない',
  '¥ / follower': '円 / follower',
  'Approve as pitcrew': 'Pitcrewとして承認',
  'Approve campaign': '承認する',
  'Reject campaign': '却下する',
  Notifications: 'お知らせ',
  Address: '住所',
  'Zip Code': '郵便番号',
  'Notification management for CMS': 'CMS内お知らせ管理',
  'Notification management for mobile application': 'アプリ内お知らせ管理',
  'Add notification about CMS': 'CMSに関するお知らせを追加します。',
  'Add notification about mobile application':
    'アプリに関するお知らせを追加します。',
  Destination: '遷移先',
  Add: '追加',
  'Notification list': 'お知らせ一覧',
  'Notification currently being delivered': '現在配信中のお知らせ',
  'Child information': 'お子様情報',
  'Fan marketing': 'ファンマーケティング',
  'Create new list': 'リストを新規作成',
  'Select fan list': 'ファンリストを選択',
  'Fan list': 'ファンリスト',
  'Edit fan list': 'ファンリスト編集',
  'List name is 255 characters or less':
    'リスト名称は255文字以下で入力してください',
  'Delete fan list': 'ファンリスト削除',
  'Do you delete fan list?': 'ファンリストを削除しますか？',
  'Fan list name': 'ファンリスト名',
  Change: '変更',
  'Do you update campaign banner?': 'スポットライトを更新しますか？',
  'Do you delete campaign banner?': 'スポットライトを削除しますか？',
  'Export selected participants': '選択中応募者エクスポート',
  RESET_ALL: '全選択解除',
  'Changes in the number of people by fan score': 'ファンスコア別人数推移',
  'male to female ratio of followers': 'ファンリストのフォロワー男女比(推定)',
  'age ratio': '年代比',
  'age ratio of followers': 'ファンリストのフォロワー年代比',
  Asc: '昇順',
  Desc: '降順',
  'Gender ratio': '男女比',
  'Please fill approve message': '承認メッセージを入力してください',
  'Number of selected posts': '選択中の投稿',
  'Back to post selection': '投稿選択に戻る',
  'You can change the display order by dragging and dropping':
    'ドラッグ&ドロップで表示順を入れ替えることができます',
  NEXT: '次へ',
  'Panel name': 'パネル名',
  'Display destination URL': '表示先URL',
  Format: 'フォーマット',
  'Is this present campaign?': 'プレゼントキャンペーンに設定',
  Presentcampaign: 'プレゼントキャンペーン',
  'Create SNS share url': 'SNSシェアURL作成',
  'SNS share url': 'SNSシェア用URL',
  'Do you want to set the SNS share URL?': 'SNSシェア用URLを設定しますか',
  'No setting': '設定なし',
  'Campaign pretask modification request(include lower check)':
    '修正依頼（薬事チェックを含む）',
  'Please wait for resubmission from kol':
    'KOLから再提出されるまでお待ちください',
  'No review required': '審査なし',
  'turn to client check': 'クライアントチェックに回す',
  'Approve message': '承認メッセージ',
  'Denied reason': '却下理由',
  Content: '内容',
  'Save memo': 'メモ保存',
  'Update the review content of the pretask':
    '投稿下書きの審査内容を更新します',
  pageTitles: {
    OVERVIEW: '概要',
    ACCOUNT: 'Account',
    ADMIN: 'Admin',
    ANALYTICS: 'Analytics',
    BRAND: 'ブランド',
    EDIT_BRAND: 'ブランドを編集',
    CAMPAIGN: 'キャンペーン',
    ORIENTATION: 'オリエンテーションシート',
    UGC_STORE: 'ストア管理',
    UGC_INSTAGRAM_POST: '投稿管理',
    UGC_SET: 'UGCセット管理',
    CHAT: 'チャット',
    CLIENT: 'Client',
    CREATE: '作成',
    DASHBOARD: 'ダッシュボード',
    Delivery: 'Delivery',
    Details: '詳細',
    Edit: '編集',
    Error: 'エラー',
    EXTERNAL_LINK: '外部リンク',
    FAN_MARKETING: 'Fan Marketing',
    Influencer: 'インフルエンサー',
    Inspection: 'Inspection',
    Instagram: 'Instagram',
    Login: 'ログイン',
    MY_COMPANY: 'My Company',
    OWN_MEMBER: 'Own Member',
    Performance: 'Performance',
    Product: '商品管理',
    Register: '登録',
    SEARCH_CONSOLE: 'Search Console',
    Shipping: 'Shipping',
    Spotlight: 'Spotlight',
    Stock: 'Stock',
    Tiktok: 'TikTok',
    Trend: 'トレンド',
    Users: 'Users',
    Warehouse: 'Warehouse',
    'Uploaded file': 'アップロードしました',
  },
  FORMS: {
    PLACEHOLDERS: {
      campaignName: 'キャンペーン名',
    },
    LABELS: {
      campaignName: 'キャンペーン名',
    },
    HELP_TEXTS: {
      campaignName: '商品やサービスがわかりやすい名前にしてください',
    },
  },
  'Campaign Name': 'キャンペーン名',
  'Campaign name description':
    'キャンペーンにはわかりやすい名前を使用してください',
  'Campaign brand section': 'キャンペーンブランド欄',
  'Please select a campaign brand': 'キャンペーンブランドを選択してください',
  'Please select a brand image':
    'ブランド画像を選択してください（一辺が600px～1080pxの正方形の画像）',
  'Brand image section': 'ブランド画像',
  'Date of campaign publish': 'キャンペーンの公開日',
  'Is Secondary use permission needed?':
    '二次利用で利用しますか？（広告クリエイティブ、SNS、店頭POPなど）',
  'Can notify on Instagram as advertisement?':
    'Lemon SquareのInstagramで告知を行いますがよろしいでしょうか？',
  'Is Brand logo use allowed?':
    '弊社HP、媒体資料に貴社ブランドロゴをクライアント様実績として使用してよろしいでしょうか？',
  'Is case study allowed?':
    '今回ご実施いただく内容を実施事例として掲載させていただいてもよろしいでしょうか？',
  'Number of Winners': '当選者数',
  'Is pharmaceutical use?':
    '医薬品（第1類、2類、3類に分類するOTC医薬品及び医療用医薬品）ですか？',
  'Is medical product use?': '食品、または飲料ですか？',
  'Product URL': '製品 URL を追加する',
  'Shipping Count': '出荷数',
  'Price of Product': '商品の価格を入力してください',
  'Shipping Address': '返送先住所',
  'shipping address zip code': '配送先住所郵便番号',
  'Should product be returned?': '在庫が余った際に返送が必要ですか？',
  'Event Date': '開催日',
  'Event Name': 'イベント名',
  'Is Store visit present?': '来店型キャンペーンですか？',
  'Is case study allowed? detail':
    '今回の実装内容を実装例として掲載したいと思います。',
  'Product URL Info': '商品URL',
  'Price Info': '価格',
  'Is Open Price?': 'オープン価格ですか？',
  'Create Camapign Confirmation': 'このキャンペーンを作成してもよろしいですか?',
  'Name error message': '3～100文字で入力してください',
  'Product URL error message': '有効なURLを入力してください！',
  'Orientation List': 'オリエンテーションリスト',
  'Create New Orientation': '新規作成',
  'Campaign Type': 'キャンペーンタイプ',
  'Information lifting date': '情報解禁日の有無',
  'Delivery Date': '納品予定日',
  'Is product type available?': '商品タイプの選択はありますか？',
  'product type description':
    'もしそうなら、あなたが提供できるサイズをリストしてください。',
  'Available sizes': '利用可能なサイズ',
  'Updated At': '更新日時',
}

const chatJa = {
  OPEN_CHANNEL_SETTINGS__OPERATOR_TITLE: 'Channel Information',
  OPEN_CHANNEL_SETTINGS__OPERATOR_URL: 'URL',
  OPEN_CHANNEL_SETTINGS__PARTICIPANTS_ACCORDION_TITLE: 'Participants',
  OPEN_CHANNEL_SETTINGS__DELETE_CHANNEL_PANEL: 'Delete channel',
  OPEN_CHANNEL_SETTINGS__DELETE_CHANNEL_TITLE: 'Delete this channel',
  OPEN_CHANNEL_SETTINGS__DELETE_CHANNEL_SUBMIT: 'Delete',
  OPEN_CHANNEL_SETTINGS__PARTICIPANTS_TITLE: 'Participants',
  OPEN_CHANNEL_SETTINGS__EMPTY_LIST: 'No participants yet',
  OPEN_CHANNEL_SETTINGS__SEE_ALL: 'See all participants',
  OPEN_CHANNEL_SETTINGS__ALL_PARTICIPANTS_TITLE: 'All participants',
  OPEN_CHANNEL_SETTINGS__NO_TITLE: '(No title)',
  OPEN_CHANNEL_CONVERSATION__TITLE_PARTICIPANTS: 'participants',
  TRYING_TO_CONNECT: 'Trying to connect…',
  USER_PROFILE__MESSAGE: 'Message',
  USER_PROFILE__USER_ID: 'User ID',
  EDIT_PROFILE__TITLE: 'My profile',
  EDIT_PROFILE__IMAGE_LABEL: 'Profile image',
  EDIT_PROFILE__IMAGE_UPLOAD: 'Upload',
  EDIT_PROFILE__NICKNAME_LABEL: 'Nickname',
  EDIT_PROFILE__NICKNAME_PLACEHOLDER: 'Enter your nickname',
  EDIT_PROFILE__USERID_LABEL: 'User ID',
  EDIT_PROFILE__THEME_LABEL: 'Dark theme',
  MESSAGE_INPUT__PLACE_HOLDER: 'Enter message',
  MESSAGE_INPUT__PLACE_HOLDER__DISABLED: 'Chat is unavailable in this channel',
  MESSAGE_INPUT__PLACE_HOLDER__MUTED:
    'Chat is unavailable because you are being muted',
  MESSAGE_INPUT__QUOTE_REPLY__PLACE_HOLDER: 'Reply to message',
  CHANNEL__MESSAGE_LIST__NOTIFICATION__NEW_MESSAGE: 'new message(s) since',
  CHANNEL__MESSAGE_LIST__NOTIFICATION__ON: 'on',
  CHANNEL_SETTING__HEADER__TITLE: 'Channel information',
  CHANNEL_SETTING__PROFILE__EDIT: 'Edit',
  CHANNEL_SETTING__MEMBERS__TITLE: 'Members',
  CHANNEL_SETTING__MEMBERS__SEE_ALL_MEMBERS: 'All members',
  CHANNEL_SETTING__MEMBERS__INVITE_MEMBER: 'Invite users',
  CHANNEL_SETTING__MEMBERS__YOU: ' (You)',
  CHANNEL_SETTING__LEAVE_CHANNEL__TITLE: 'Leave channel',
  CHANNEL_SETTING__OPERATORS__TITLE: 'Operators',
  CHANNEL_SETTING__OPERATORS__TITLE_ALL: 'All operators',
  CHANNEL_SETTING__OPERATORS__TITLE_ADD: 'Add operator',
  CHANNEL_SETTING__MUTED_MEMBERS__TITLE: 'Muted members',
  CHANNEL_SETTING__BANNED_MEMBERS__TITLE: 'Banned members',
  CHANNEL_SETTING__FREEZE_CHANNEL: 'Freeze Channel',
  BUTTON__CANCEL: 'Cancel',
  BUTTON__DELETE: 'Delete',
  BUTTON__SAVE: 'Save',
  BUTTON__CREATE: 'Create',
  BUTTON__INVITE: 'Invite',
  BADGE__OVER: '+',
  MODAL__DELETE_MESSAGE__TITLE: 'Delete this message?',
  MODAL__CHANNEL_INFORMATION__TITLE: 'Edit channel information',
  MODAL__CHANNEL_INFORMATION__CHANNEL_IMAGE: 'Channel image',
  MODAL__CHANNEL_INFORMATION__UPLOAD: 'Upload',
  MODAL__CHANNEL_INFORMATION__CHANNEL_NAME: 'Channel name',
  MODAL__CHANNEL_INFORMATION__INPUT__PLACE_HOLDER: 'Enter name',
  MODAL__INVITE_MEMBER__TITLE: 'Invite member',
  MODAL__INVITE_MEMBER__SELECTEC: 'selected',
  MODAL__CHOOSE_CHANNEL_TYPE__TITLE: 'New channel',
  MODAL__CHOOSE_CHANNEL_TYPE__GROUP: 'Group',
  MODAL__CHOOSE_CHANNEL_TYPE__SUPER_GROUP: 'Super group',
  MODAL__CHOOSE_CHANNEL_TYPE__BROADCAST: 'Broadcast',
  MODAL__CREATE_CHANNEL__TITLE: 'New channel',
  MODAL__CREATE_CHANNEL__GROUP: 'Group',
  MODAL__CREATE_CHANNEL__SUPER: 'Super group',
  MODAL__CREATE_CHANNEL__BROADCAST: 'Broadcast',
  MODAL__CREATE_CHANNEL__SELECTED: 'selected',
  TYPING_INDICATOR__IS_TYPING: 'is typing...',
  TYPING_INDICATOR__AND: 'and',
  TYPING_INDICATOR__ARE_TYPING: 'are typing...',
  TYPING_INDICATOR__MULTIPLE_TYPING: 'Several people are typing...',
  CHANNEL_FROZEN: 'Channel frozen',
  PLACE_HOLDER__NO_CHANNEL: 'No channels',
  PLACE_HOLDER__WRONG: 'Something went wrong',
  PLACE_HOLDER__RETRY_TO_CONNECT: 'Retry',
  PLACE_HOLDER__NO_MESSAGES: 'No messages',
  NO_TITLE: 'No title',
  NO_NAME: '(No name)',
  NO_MEMBERS: '(No members)',
  TOOLTIP__AND_YOU: ', and you',
  TOOLTIP__YOU: 'you',
  TOOLTIP__UNKNOWN_USER: '(no name)',
  UNKNOWN__UNKNOWN_MESSAGE_TYPE: '(Unknown message type)',
  UNKNOWN__CANNOT_READ_MESSAGE: 'Cannot read this message.',
  MESSAGE_EDITED: '(edited)',
  MESSAGE_MENU__COPY: 'Copy',
  MESSAGE_MENU__REPLY: 'Reply',
  MESSAGE_MENU__EDIT: 'Edit',
  MESSAGE_MENU__RESEND: 'Resend',
  MESSAGE_MENU__DELETE: 'Delete',
  SEARCH: 'Search',
  SEARCH_IN_CHANNEL: 'Search in channel',
  SEARCH_IN: 'Search in',
  SEARCHING: 'Searching for messages...',
  NO_SEARCHED_MESSAGE: 'No results found.',
  QUOTE_MESSAGE_INPUT__REPLY_TO: 'Reply to',
  QUOTE_MESSAGE_INPUT__FILE_TYPE_IMAGE: 'Photo',
  QUOTE_MESSAGE_INPUT__FILE_TYPE_GIF: 'GIF',
  QUOTE_MESSAGE_INPUT__FILE_TYPE__VIDEO: 'Video',
  QUOTED_MESSAGE__REPLIED_TO: 'replied to',
  QUOTED_MESSAGE__CURRENT_USER: 'You',
  CONTEXT_MENU_DROPDOWN__COPY: 'Copy',
  CONTEXT_MENU_DROPDOWN__EDIT: 'Edit',
  CONTEXT_MENU_DROPDOWN__RESEND: 'Resend',
  CONTEXT_MENU_DROPDOWN__DELETE: 'Delete',
  MENTION_NAME__NO_NAME: '(No name)',
  MENTION_COUNT__OVER_LIMIT: 'You can mention up to %d times per message.',
}

export type JapaneseTranslation = typeof ja
export type JapaneseChatTranslation = typeof chatJa

export { ja, chatJa }
