/* eslint-disable arrow-body-style */
// import Script from 'next/script'

const LemonUmamiScript = () => {
  return null

  // return (
  //   <Script id='lemon-umami' strategy='afterInteractive'>
  //     {`
  //     const DOMAIN = 'https://analytics.alpha-lemon.cchan.tv';
  //     const TRACKER_SCRIPT_NAME = 'umami';
  //     const WEBSITE_ID = 'affc77d1-26e6-455b-baac-32e4d3cf77f0';
  //     const AUTO_TRACK = 'true';
  //     const DO_NOT_TRACK = 'false';
  //     const CACHE = 'true';

  //     const script = document.createElement('script');
  //     script.setAttribute('src', \`\${DOMAIN}/\${TRACKER_SCRIPT_NAME}.js\`);
  //     script.setAttribute('data-website-id', WEBSITE_ID);
  //     script.setAttribute('data-auto-track', AUTO_TRACK);
  //     script.setAttribute('data-do-not-track', DO_NOT_TRACK);
  //     script.setAttribute('data-cache', CACHE);
  //     script.setAttribute('async', '');
  //     script.setAttribute('defer', '');
  //     // initialize script
  //     document.head.appendChild(script);
  //   `}
  //   </Script>
  // )
}

export { LemonUmamiScript }
