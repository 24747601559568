import { withTRPC } from '@trpc/next'

const MyAppWithTRPC = withTRPC({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  config({ ctx }) {
    // const token =
    //   typeof window !== 'undefined' && window.localStorage.getItem('auth_token')
    //     ? JSON.parse(window.localStorage.getItem('auth_token')!).token
    //     : undefined

    const url = `${process.env.NEXT_PUBLIC_LEMON_API}/trpc`

    return {
      // optional - adds superjson serialization
      // transformer: superjson,
      url,
      // headers: {
      //   authorization: token,
      // },
      /**
       * @link https://react-query.tanstack.com/reference/QueryClient
       */
      // queryClientConfig: { defaultOptions: { queries: { staleTime: 60 } } },
    }
  },

  /**
   * @link https://trpc.io/docs/ssr
   */
  ssr: false,
})

export { MyAppWithTRPC }
